import logo from "../../Resources/holidaysherpa.png";
import firebase from "../../Utils/firebase";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../Utils/UserContext";
import "./header2.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

function Header(props) {
  const { authState, setAuthState } = useContext(UserContext);

  const history = useHistory();
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setAuthState({ initializing: false, user: null, mfa_enabled: false});
        history.push("/holidaysherpa/login");
      })
      .catch((err) => {
        err;
      });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        variant="light"
        fixed="top"
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Container fluid>
          <Navbar.Brand href="/" className="brand" 
            onClick={(e)=>{
              e.preventDefault();
              history.push('/');
            }}
          >
            <img src={logo} alt="LegalAILogo" height='35px'/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {authState.user ? 
                authState.mfa_enabled ?
                  firebase.auth().currentUser.email.endsWith("@legalai.io") ? (
                    <>
                      <Nav.Link href="/admin">Admin</Nav.Link>
                      <Nav.Link href="/editform">Edit Form</Nav.Link>
                      <NavDropdown title="Documents" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/maindocument">
                          Main Document
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/subdocument">
                          Sub Document
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link href="/similarityEngine">Similarity Engine</Nav.Link>
                      <Nav.Link href="/clients">Clients</Nav.Link>
                      <Nav.Link href="/holidaysherpa">Holidaysherpa</Nav.Link>
                    </>
                    ): (
                    <></>
                  ): (
                  <></>
                ): (
                <></>
              )}
            </Nav>
            <Nav className="ml-auto login">
              {authState.user ? (
                <Button
                  className="col-3 col-md-12"
                  onClick={signOut}
                  // variant="primary"
                  style={{
                    backgroundColor: "#3249B4",
                    borderRadius: '0px'
                  }}
                >
                  ABMELDEN
                </Button>
              ) : (
                <Button
                  onClick={() => history.push("/login")}
                  // variant="primary"
                  style={{
                    backgroundColor: "#3249B4",
                    borderRadius: '0px'
                  }}
                >
                  ANMELDEN
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
